import React, { useRef, useState } from 'react';

import {
  Collapse,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
  Text,
} from '@chakra-ui/react';
import {
  EColor,
  MagnifyingGlass,
  CloseCircleIcon,
  useIsMobile,
} from '@fanatics-live/common-components';
import PropTypes from 'prop-types';
import { useGTMEvents } from '~/hooks/useGTMEvents';

const TITLE_SEARCH_MIN_CHARS = 2;

function SearchBar({
  searchText,
  setSearchText,
  isPending,
  setFilters,
  filters,
}) {
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const { pushGTMEvent } = useGTMEvents();
  const isMobile = useIsMobile();
  const inputRef = useRef();

  const onSearchBarClick = () => {
    pushGTMEvent('salesHistorySearchClick');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      searchText.length >= TITLE_SEARCH_MIN_CHARS ||
      searchText.length === 0
    ) {
      setAttemptedSubmit(false);
      setFilters({
        ...filters,
        title: searchText,
      });
      inputRef.current.blur();
    } else {
      setAttemptedSubmit(true);
    }
  };

  return (
    <Flex
      direction="column"
      maxWidth={isFocused || searchText || !isMobile ? '285px' : '59px'}
      transition="max-width 0.2s ease-in-out"
      width="100%"
    >
      <form onSubmit={handleSubmit}>
        <InputGroup
          bg="white"
          borderRadius="100px"
          h="40px"
          maxWidth="285px"
          width="100%"
        >
          <InputLeftElement h="40px" pointerEvents="none">
            {isPending ? (
              <Spinner marginLeft="20px" size="sm" />
            ) : (
              <MagnifyingGlass
                color={EColor.Black}
                height="24px"
                marginLeft="20px"
                width="24px"
              />
            )}
          </InputLeftElement>
          <Input
            _focus={{
              borderWidth: '1px',
              borderColor: 'black',
              boxShadow: 'none',
            }}
            _placeholder={{
              color: EColor.Black,
              fontSize: '16px',
              lineHeight: '26px',
              fontWeight: 400,
              fontFamily: 'ABC Diatype',
            }}
            background={EColor.White}
            borderColor={EColor.Neutral25}
            borderRadius="100px"
            borderWidth="1px"
            color={EColor.Black}
            fontWeight="500"
            h="40px"
            maxWidth="285px"
            paddingLeft="50px"
            paddingRight="35px"
            placeholder="Search Sold Items..."
            ref={inputRef}
            transitionDuration="0.3s"
            value={searchText}
            onBlur={() => setIsFocused(false)}
            onChange={(event) => {
              setSearchText(event.target.value);
            }}
            onClick={onSearchBarClick}
            onFocus={() => setIsFocused(true)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleSubmit(event);
              }
            }}
          />
          <input hidden type="submit" />
          {!!searchText && (
            <InputRightElement
              aria-label="close-icon"
              as="button"
              h="40px"
              lineHeight="10px"
              role="button"
              onClick={() => {
                setSearchText('');
                setAttemptedSubmit(false);
                setFilters({
                  ...filters,
                  title: '',
                });
              }}
            >
              <CloseCircleIcon
                _hover={{
                  color: EColor.BrandDark,
                  cursor: 'pointer',
                }}
                boxSize="20px"
                color={EColor.Neutral15}
                height="16px"
                transitionDuration="0.2s"
                width="16px"
              />
            </InputRightElement>
          )}
        </InputGroup>
      </form>
      <Collapse
        in={
          searchText.length !== 0 &&
          searchText.length < TITLE_SEARCH_MIN_CHARS &&
          attemptedSubmit
        }
      >
        <Text color="#666" fontSize="12px" fontWeight="600" pt="5px">
          Enter at least {TITLE_SEARCH_MIN_CHARS} characters to search
        </Text>
      </Collapse>
    </Flex>
  );
}

SearchBar.propTypes = {
  searchText: PropTypes.string,
  setSearchText: PropTypes.func,
  isPending: PropTypes.bool,
  setFilters: PropTypes.func,
  filters: PropTypes.object,
};

export default SearchBar;
