import React from 'react';

import { createIcon } from '@chakra-ui/react';

export const EyeAppealAIcon = createIcon({
  viewBox: '0 0 19 24',
  path: (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 19 24"
      width="19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill="black" height="21" width="19" y="3" />
      <rect fill="#C286BE" height="3" width="19" />
      <path
        d="M8.32064 8H10.6778L15.2035 19H12.8935L11.7935 15.9986H7.11064L5.99492 19H3.79492L8.32064 8ZM9.89206 10.8286C9.71397 10.3571 9.57254 9.93286 9.46778 9.55571C9.4154 9.70238 9.35254 9.88571 9.27921 10.1057C9.20587 10.3152 9.11683 10.5562 9.01206 10.8286L7.75492 14.2543H11.1492L9.89206 10.8286Z"
        fill="white"
      />
    </svg>
  ),
});
