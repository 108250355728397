import PropTypes from 'prop-types';

export const filterTypes = {
  yearMin: PropTypes.string,
  yearMax: PropTypes.string,
  category: PropTypes.arrayOf(PropTypes.string),
  eyeAppealGrade: PropTypes.arrayOf(PropTypes.string),
  gradingService: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  sort: PropTypes.string,
  auctionTypes: PropTypes.string,
};

export default PropTypes.shape(filterTypes);
