import { useMemo, useState } from 'react';

export const usePlaceholderImage = (imageUrl = '/images/placeholder.jpg') => {
  const [hasImageError, setHasImageError] = useState(false);
  const imageOrPlaceholderUrl = useMemo(
    () => (hasImageError ? '/images/placeholder.jpg' : imageUrl),
    [imageUrl, hasImageError]
  );
  const setPlaceholder = () => setHasImageError(true);

  return [imageOrPlaceholderUrl, setPlaceholder];
};
