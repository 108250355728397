import React from 'react';

import { Button } from '@chakra-ui/react';
import {
  useModalStore,
  EModalContent,
  EButtonVariant,
} from '@fanatics-live/common-components';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';

export function LoginButton({ children, ...props }) {
  const modalStore = useModalStore();
  const location = useLocation();
  const redirectUrl = location.state?.mainPagePath ?? window.location.href;

  return (
    <Button
      variant={EButtonVariant.BRAND_PRIMARY}
      onClick={() => {
        modalStore.navigate({ name: EModalContent.COLLECT_LOGIN, redirectUrl });
      }}
      {...props}
    >
      {children || 'Log In For Price'}
    </Button>
  );
}

LoginButton.propTypes = {
  children: PropTypes.node,
};
