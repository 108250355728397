import React from 'react';

import { Box, Text, VStack, useRadioGroup } from '@chakra-ui/react';
import { EColor, ETextVariant } from '@fanatics-live/common-components';
import PropTypes from 'prop-types';
import { RadioButton } from '~/components/RadioButton';
import filterParams from '~/features/research/types/Filters';

function MarketplaceSection({ filters, setFilters, marketplaces }) {
  const { getRootProps, getRadioProps } = useRadioGroup({
    value: filters.auctionTypes,
    onChange: (selected) => {
      setFilters({
        ...filters,
        auctionTypes: selected,
        page: null,
      });
    },
  });

  const group = getRootProps();

  const allRadioButton = getRadioProps({ value: '' });

  return (
    <Box borderBottomColor={EColor.Neutral10} borderBottomWidth="1px" pb="24px">
      <Text color={EColor.Black} my="24px" variant={ETextVariant.SmallBold}>
        Marketplace
      </Text>
      <VStack alignItems="flex-start" spacing="24px" width="100%" {...group}>
        <RadioButton key="sortby-all" {...allRadioButton}>
          <Text color={EColor.Neutral65} variant={ETextVariant.Small}>
            All
          </Text>
        </RadioButton>
        {marketplaces.map((c) => {
          const radio = getRadioProps({ value: c.key });
          return (
            <RadioButton key={`sortby-${c.key}`} {...radio}>
              <Text color={EColor.Neutral65} variant={ETextVariant.Small}>
                {c.name}
              </Text>
            </RadioButton>
          );
        })}
      </VStack>
    </Box>
  );
}

MarketplaceSection.propTypes = {
  filters: filterParams,
  setFilters: PropTypes.func,
  marketplaces: PropTypes.array,
};

export default MarketplaceSection;
