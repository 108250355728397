import React from 'react';

import { Box, VStack, Text } from '@chakra-ui/react';
import { EColor, ETextVariant } from '@fanatics-live/common-components';
import PropTypes from 'prop-types';
import FilterItem from '~/components/FilterItem';

function EyeAppealSection({ filters, setFilters, eyeAppeal }) {
  return (
    <Box borderBottomColor={EColor.Neutral10} borderBottomWidth="1px" pb="24px">
      <Text color={EColor.Black} my="24px" variant={ETextVariant.SmallBold}>
        Eye Appeal
      </Text>
      <VStack alignItems="flex-start" spacing="14px" width="100%">
        <FilterItem
          name="All"
          selected={
            !filters.eyeAppealGrade || filters.eyeAppealGrade.length < 1
          }
          onClick={() =>
            setFilters({
              ...filters,
              eyeAppealGrade: null,
              page: null,
            })
          }
        />
        {eyeAppeal.map((c) => {
          const isSelected =
            (filters.eyeAppealGrade &&
              filters.eyeAppealGrade.indexOf(c.key) !== -1) ||
            false;
          return (
            <FilterItem
              key={`eyeappeal-${c.key}`}
              name={c.name}
              selected={isSelected}
              onClick={() => {
                let removed;
                if (isSelected) {
                  removed = [...filters.eyeAppealGrade];
                  removed.splice(filters.eyeAppealGrade.indexOf(c.key), 1);
                }
                setFilters({
                  ...filters,
                  eyeAppealGrade: isSelected
                    ? [...removed]
                    : [...(filters.eyeAppealGrade || []), c.key],
                  page: null,
                });
              }}
            />
          );
        })}
      </VStack>
    </Box>
  );
}

EyeAppealSection.propTypes = {
  setFilters: PropTypes.func,
  filters: PropTypes.object,
  eyeAppeal: PropTypes.array,
};

export default EyeAppealSection;
