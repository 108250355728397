import React, { useCallback, useEffect, useState } from 'react';

import { omit, pick } from 'lodash';

import { Flex } from '@chakra-ui/react';
import { useAuth } from '@fanatics-live/common-components';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';
import useSWR from 'swr';
import { useCookieState } from 'use-cookie-state';
import { useActiveFilters } from '~/features/research/components/SearchFilterBar/hooks/useActiveFilters';
import { useSearchResults } from '~/features/research/hooks/useSearchResults';
import { filterTypes } from '~/features/research/types/Filters';
import SalesHistory from '~/features/research/types/SalesHistory';
import Stats from '~/features/research/types/Stats';
import { isClient } from '~/utils/isClient';
import { getCurrentUrl } from '~/utils/req';
import { getURLParams, makeURLParams } from '~/utils/utils';

import ResearchHeader from '../features/research/components/ResearchHeader';
import SearchFilterBar from '../features/research/components/SearchFilterBar';
import SearchResults from '../features/research/components/SearchResults';

const DEFAULT_COOKIE_EXPIRES = new Date(Date.now() + 1000 * 60 * 60 * 24 * 365); // 1 year

function Research({
  initialStatsData = {},
  initialSearchData,
  initialLayout,
  initialAreFiltersOpen,
  initialUrl,
}) {
  const history = useHistory();
  const auth = useAuth();
  const loggedIn = auth?.isAuthenticated();
  const params = getURLParams(history?.location?.search);
  const validKeys = Object.keys(filterTypes);
  const filterParams = pick(params, validKeys);
  const otherParams = omit(params, validKeys);

  const [filters, setFilters] = useState({ ...filterParams });
  const [showFilters, setShowFilters] = useCookieState(
    'filters-open',
    initialAreFiltersOpen,
    {
      encode: {
        encode: (value) => String(value),
        expires: DEFAULT_COOKIE_EXPIRES,
      },
      decode: {
        decode: (value) => value === 'true',
      },
    }
  );
  // const [display, setDisplay] = useCookieState(
  //   'sales-history-layout',
  //   initialLayout,
  //   {
  //     encode: {
  //       expires: DEFAULT_COOKIE_EXPIRES,
  //     },
  //   }
  // );

  const setFiltersCallback = useCallback(
    (newFilters) => {
      if (history.location.pathname === '/') {
        const validFilters = pick(newFilters, validKeys);
        setFilters(validFilters);
      }
      const urlParams = makeURLParams({ ...newFilters, ...otherParams }) || '';
      if (isClient() && urlParams === window.location.search.slice(1)) return;

      history.push({
        pathname: history.location.pathname,
        search: urlParams,
      });
    },
    [history, otherParams, validKeys]
  );

  useEffect(() => {
    setFiltersCallback(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location]);

  const activeFilters = useActiveFilters(filters);

  const { searchData, totalElements, getNextPage, hasMorePages, isPending } =
    useSearchResults(filters, initialSearchData, loggedIn);

  // if (searchData && searchData._embedded) {
  // setState({ _embedded: { SalesRecords } });
  // searchData._embedded.SalesRecords = SalesRecords;
  // }
  const { data: stats } = useSWR('/api/v1/pub/sales/stats', {
    focusThrottleInterval: 0,
    fallbackData: initialStatsData,
  });

  return (
    <>
      <Helmet>
        <title>Fanatics Collect Sales History</title>
        <link
          href={isClient() ? window.location.href : initialUrl}
          rel="canonical"
        />
      </Helmet>
      <Flex direction="column" justifyContent="center">
        <ResearchHeader loggedIn={loggedIn} />
        <SearchFilterBar
          activeFilters={activeFilters}
          display="grid"
          filters={filters}
          isPending={isPending}
          setFilters={setFiltersCallback}
          setShowFilters={setShowFilters}
          showFilters={!!showFilters}
        />
        <Flex justifyContent="center" py={{ base: 2, md: 6 }} width="100%">
          <SearchResults
            activeFilters={activeFilters}
            data={searchData}
            getNextPage={getNextPage}
            hasMorePages={hasMorePages}
            isPending={isPending}
            loggedIn={loggedIn}
            setFilters={setFiltersCallback}
            totalElements={totalElements}
            totalRecords={stats?.totalRecords}
          />
        </Flex>
      </Flex>
    </>
  );
}

Research.getInitialProps = async ({ apiClient, auth, req }) => {
  if (isClient()) {
    return;
  }
  try {
    const initialLayout = isClient()
      ? 'grid'
      : req.cookies['sales-history-layout'] || 'grid';
    const initialAreFiltersOpen = isClient()
      ? false
      : req.cookies['filters-open'] === 'true' || false;

    const initialUrl = getCurrentUrl(req);

    return {
      initialLayout,
      initialAreFiltersOpen,
      initialUrl,
    };
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('e', e);
  }
  return {};
};

Research.propTypes = {
  initialStatsData: Stats,
  initialSearchData: SalesHistory,
  initialLayout: PropTypes.string,
  initialAreFiltersOpen: PropTypes.bool,
  initialUrl: PropTypes.string,
};

export default Research;
