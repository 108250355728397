const { filterOptions } = require('./constants');

export function getFilterByValue(value, filterName) {
  return filterOptions[filterName]?.find(({ name, key }) =>
    [name, key].includes(value)
  );
}

export const getTotalFilters = (filters) => {
  const formattedFilters = Object.entries(filters).reduce(
    (acc, [key, filter]) => {
      if (Array.isArray(filter)) {
        return {
          total: acc.total + filter.length,
          yearExist: acc.yearExist,
        };
      }
      if ((key === 'yearMin' || key === 'yearMax') && !!filter) {
        if (!acc.yearExist) {
          return { total: acc.total + 1, yearExist: true };
        }
        return acc;
      }
      if (filter) {
        return { total: acc.total + 1, yearExist: acc.yearExist };
      }
      return acc;
    },
    { total: 0, yearExist: false }
  );
  return formattedFilters.total ?? 0;
};
