import React from 'react';

import { Box, Flex, Text, Image } from '@chakra-ui/react';
import {
  EColor,
  ETextVariant,
  useAuth,
} from '@fanatics-live/common-components';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import ItemPrice from '~/components/ItemPrice';
import { LoginButton } from '~/components/LoginButton';
import SoldIn from '~/components/SoldIn';
import AuctionType from '~/features/research/types/AuctionType';
import { usePlaceholderImage } from '~/hooks/usePlaceholderImage';

import {
  EyeAppealAIcon,
  EyeAppealEIcon,
  EyeAppealSIcon,
} from '../../../../components/icons';
import { EyeAppeal } from '../../types/EyeAppealType';

function GridItem({
  title,
  id,
  smallImage1,
  mediumImage1,
  subtitle,
  eyeAppealGrade,
  loggedIn,
  auctionType,
  purchasePrice,
  soldDate,
}) {
  const { isFetchingProfile } = useAuth();
  const [imageUrl, setPlaceholder] = usePlaceholderImage(
    smallImage1 ?? mediumImage1
  );

  return (
    <Flex
      _hover={{
        boxShadow: '2xl',
      }}
      bg="white"
      direction="column"
      justify="space-between"
      position="relative"
      style={{ transition: 'all 800ms cubic-bezier(0.19, 1, 0.22, 1)' }}
      zIndex={1}
    >
      {eyeAppealGrade && (
        <Box position="absolute" right="16px" top="16px">
          {eyeAppealGrade === EyeAppeal.PWCC_A && (
            <EyeAppealAIcon h="21px" title={EyeAppeal.PWCC_A} w="20px" />
          )}
          {eyeAppealGrade === EyeAppeal.PWCC_E && (
            <EyeAppealEIcon h="21px" title={EyeAppeal.PWCC_E} w="20px" />
          )}
          {eyeAppealGrade === EyeAppeal.PWCC_S && (
            <EyeAppealSIcon h="21px" title={EyeAppeal.PWCC_S} w="20px" />
          )}
        </Box>
      )}
      <Box
        alignItems="center"
        as={RouterLink}
        borderBottom={`1px solid ${EColor.Neutral2}`}
        display="flex"
        h="330px"
        to={{
          pathname: `/items/${id}`,
          state: { mainPagePath: window.location.href },
        }}
      >
        <Flex
          height="100%"
          justify="center"
          position="relative"
          px="32px"
          py="32px"
          width="100%"
        >
          <Flex
            alignItems="center"
            flexDirection="column"
            height="100%"
            justifyContent="center"
          >
            <Image
              alt="Card image"
              borderRadius="5px"
              boxShadow="0px 8px 36px 0px #0000002B"
              loading="lazy"
              maxHeight="266px"
              objectFit="contain"
              src={imageUrl}
              onError={setPlaceholder}
            />
          </Flex>
        </Flex>
      </Box>

      <Box pb="16px" pt="22px" px="16px">
        <Text mb="8px" variant={ETextVariant.BaseBold}>
          {title}
        </Text>
        <Text color={EColor.Neutral55} variant={ETextVariant.XS2}>
          {subtitle}
          &nbsp;
        </Text>
      </Box>

      <Box pb="26px" px="16px">
        {!loggedIn && !isFetchingProfile ? (
          <LoginButton height="32px" mb="8px" px="12px" />
        ) : (
          <ItemPrice purchasePrice={purchasePrice} />
        )}
        <SoldIn name={auctionType} soldDate={soldDate} />
      </Box>
    </Flex>
  );
}

GridItem.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  smallImage1: PropTypes.string,
  mediumImage1: PropTypes.string,
  eyeAppealGrade: PropTypes.string,
  subtitle: PropTypes.string,
  loggedIn: PropTypes.bool,
  auctionType: AuctionType,
  purchasePrice: PropTypes.string,
  soldDate: PropTypes.string,
};

export default GridItem;
