import React, { useMemo } from 'react';

import { format, parseISO } from 'date-fns';

import { Text } from '@chakra-ui/react';
import { EColor, ETextVariant } from '@fanatics-live/common-components';
import { PropTypes } from 'prop-types';

const marketplacesMap = {
  flash: 'Auction',
  private: 'Private Sale',
  fixed: 'Buy Now',
  monthly: 'Auction',
  weekly: 'Auction',
  premier: 'Premier',
};

function SoldIn({ name, soldDate, ...restProps }) {
  const currentMarketplace = marketplacesMap[name?.toLowerCase()];

  const date = useMemo(
    () =>
      !!soldDate && format(parseISO(soldDate.split(' ')[0]), 'MMM dd, yyyy'),
    [soldDate]
  );

  return (
    <Text
      color={EColor.Neutral55}
      variant={ETextVariant.XS2}
      {...restProps}
    >{`Sold on ${date} in ${currentMarketplace}`}</Text>
  );
}

SoldIn.propTypes = {
  name: PropTypes.string,
  soldDate: PropTypes.string,
};

export default SoldIn;
