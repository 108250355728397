import React, { useMemo } from 'react';

import { Box, Text, VStack } from '@chakra-ui/react';
import { EColor, ETextVariant } from '@fanatics-live/common-components';
import PropTypes from 'prop-types';
import FilterItem from '~/components/FilterItem';

function CategorySection({ filters, setFilters, categories, otherItems }) {
  const formattedCategories = useMemo(
    () => categories.map((category) => category.name),
    [categories]
  );

  const formattedOtherItems = useMemo(
    () => otherItems.map((item) => item.name),
    [otherItems]
  );

  const isTradingCardsSelected = useMemo(
    () =>
      filters.category
        ? formattedCategories.every((category) =>
            filters.category.includes(category)
          )
        : false,
    [formattedCategories, filters.category]
  );

  const isOtherItemsSelected = useMemo(
    () =>
      filters.category
        ? formattedOtherItems.every((item) => filters.category.includes(item))
        : false,
    [filters.category, formattedOtherItems]
  );

  return (
    <Box borderBottomColor={EColor.Neutral10} borderBottomWidth="1px" py="24px">
      <Text color={EColor.Black} my="24px" variant={ETextVariant.SmallBold}>
        Category
      </Text>
      <FilterItem
        isSubcategory
        mb="24px"
        name="All"
        selected={!filters.category || filters.category.length < 1}
        onClick={() => setFilters({ ...filters, category: null, page: null })}
      />
      <FilterItem
        isSubcategory
        key="cat-trading-cards"
        mb="24px"
        name="Trading Cards"
        selected={isTradingCardsSelected}
        onClick={() => {
          let removed;
          if (isTradingCardsSelected) {
            removed = [...filters.category];
            formattedCategories.forEach((category) => {
              removed = removed.splice(filters.category.indexOf(category), 1);
            });
          }
          setFilters({
            ...filters,
            category: isTradingCardsSelected
              ? [...removed]
              : [...(filters.category || []), ...formattedCategories],
            page: null,
          });
        }}
      />
      <VStack
        alignItems="flex-start"
        borderBottomColor={EColor.Neutral10}
        borderBottomWidth="1px"
        mb="20px"
        pb="24px"
        spacing="24px"
        width="100%"
      >
        {categories.map((c) => {
          const isSelected =
            (filters.category && filters.category.indexOf(c.name) !== -1) ||
            false;
          return (
            <FilterItem
              key={`cat-${c.name}`}
              name={c.name}
              selected={isSelected}
              onClick={() => {
                let removed;
                if (isSelected) {
                  removed = [...filters.category];
                  removed.splice(filters.category.indexOf(c.name), 1);
                }
                setFilters({
                  ...filters,
                  category: isSelected
                    ? [...removed]
                    : [...(filters.category || []), c.name],
                  page: null,
                });
              }}
            />
          );
        })}
      </VStack>
      <FilterItem
        isSubcategory
        key="cat-trading-miscellaneous"
        mb="24px"
        name="Miscellaneous"
        selected={isOtherItemsSelected}
        onClick={() => {
          let removed;
          if (isOtherItemsSelected) {
            removed = [...filters.category];
            formattedOtherItems.forEach((item) => {
              removed = removed.splice(filters.category.indexOf(item), 1);
            });
          }
          setFilters({
            ...filters,
            category: isOtherItemsSelected
              ? [...removed]
              : [...(filters.category || []), ...formattedOtherItems],
            page: null,
          });
        }}
      />
      <VStack alignItems="flex-start" spacing="24px" width="100%">
        {otherItems.map((c) => {
          const isSelected =
            (filters.category && filters.category.indexOf(c.name) !== -1) ||
            false;
          return (
            <FilterItem
              key={`cat-${c.name}`}
              name={c.name}
              selected={isSelected}
              onClick={() => {
                let removed;
                if (isSelected) {
                  removed = [...filters.category];
                  removed.splice(filters.category.indexOf(c.name), 1);
                }
                setFilters({
                  ...filters,
                  category: isSelected
                    ? [...removed]
                    : [...(filters.category || []), c.name],
                  page: null,
                });
              }}
            />
          );
        })}
      </VStack>
    </Box>
  );
}

CategorySection.propTypes = {
  setFilters: PropTypes.func,
  filters: PropTypes.object,
  categories: PropTypes.array,
  otherItems: PropTypes.array,
};

export default CategorySection;
