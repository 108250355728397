/* eslint-disable no-param-reassign */
import _ from 'lodash';

import qs from 'qs';

const removeEmpty = (obj) => {
  if (obj && (obj.page === 0 || obj.page === '0')) {
    delete obj.page;
  }
  if (obj.page) {
    delete obj.page;
  }
  Object.entries(obj).forEach(
    ([key, val]) =>
      (val && typeof val === 'object' && removeEmpty(val)) ||
      ((val === null || val === '') && delete obj[key])
  );
  return obj;
};

export function getURLParams(url) {
  const queryParams = (url || '').replace(/([\s\S]*?\?)/, '');

  const params = qs.parse(queryParams);
  return _.mapValues(params, (value, key) =>
    ['eyeAppealGrade', 'gradingService', 'category'].includes(key)
      ? value.split(',')
      : value
  );
}

export function makeURLParams(params = {}) {
  return qs.stringify(removeEmpty({ ...params }), { arrayFormat: 'comma' });
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

const formatterWithDecimals = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

export function formatNumber(x) {
  return new Intl.NumberFormat().format(x);
}

export const formatToMoney = (money, useDecimals = false) => {
  if (money === undefined) {
    return '';
  }
  const dollars = typeof money === 'number' ? money : Number(money).toFixed(2);

  if (useDecimals) return formatterWithDecimals.format(dollars);
  return formatter.format(dollars);
};
