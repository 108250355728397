import React from 'react';

import { Box, useRadio } from '@chakra-ui/react';
import { EColor } from '@fanatics-live/common-components';
import PropTypes from 'prop-types';

export const RadioButton = ({ children, ...restProps }) => {
  const { getInputProps, getCheckboxProps } = useRadio({ ...restProps });

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box
      alignItems="center"
      as="label"
      cursor="pointer"
      display="flex"
      gap="8px"
      width="100%"
    >
      <input {...input} />
      <Box
        {...checkbox}
        alignItems="center"
        borderColor={EColor.Black}
        borderRadius="50%"
        borderWidth="1px"
        cursor="pointer"
        display="flex"
        height="20px"
        justifyContent="center"
        width="20px"
      >
        <Box
          backgroundColor={EColor.Black}
          borderRadius="50%"
          height="14px"
          visibility={input.checked ? 'visible' : 'hidden'}
          width="14px"
        />
      </Box>

      {children}
    </Box>
  );
};

RadioButton.propTypes = {
  children: PropTypes.node,
};
