import React from 'react';

import { Box, Text, VStack, useRadioGroup } from '@chakra-ui/react';
import { EColor, ETextVariant } from '@fanatics-live/common-components';
import PropTypes from 'prop-types';
import { RadioButton } from '~/components/RadioButton';
import filterParams from '~/features/research/types/Filters';

function SortBySection({ filters, setFilters, sorts }) {
  const { getRootProps, getRadioProps } = useRadioGroup({
    value: filters.sort,
    onChange: (selected) => {
      setFilters({
        ...filters,
        sort: selected,
        page: null,
      });
    },
  });

  const group = getRootProps();

  return (
    <Box borderBottomColor={EColor.Neutral10} borderBottomWidth="1px" pb="24px">
      <Text color={EColor.Black} my="24px" variant={ETextVariant.SmallBold}>
        Sort by
      </Text>
      <VStack alignItems="flex-start" spacing="24px" width="100%" {...group}>
        {sorts.map((c) => {
          const radio = getRadioProps({ value: c.key });
          return (
            <RadioButton key={`sortby-${c.key}`} {...radio}>
              <Text color={EColor.Neutral65} variant={ETextVariant.Small}>
                {c.name}
              </Text>
            </RadioButton>
          );
        })}
      </VStack>
    </Box>
  );
}

SortBySection.propTypes = {
  filters: filterParams,
  setFilters: PropTypes.func,
  sorts: PropTypes.array,
};

export default SortBySection;
