import React from 'react';

import { Box, Flex, Text, Icon } from '@chakra-ui/react';
import {
  EColor,
  ETextVariant,
  SelectedIcon,
} from '@fanatics-live/common-components';
import PropTypes from 'prop-types';

function FilterItem({
  name,
  onClick,
  selected = true,
  isSubcategory,
  ...rest
}) {
  return (
    <Flex
      alignItems="center"
      cursor="pointer"
      gap="8px"
      justifyContent="start"
      pl={isSubcategory ? 0 : '16px'}
      width="100%"
      onClick={onClick}
      {...rest}
    >
      {!selected && (
        <Box
          borderColor={EColor.Neutral35}
          borderRadius="4px"
          borderWidth="1px"
          boxSize="20px"
        >
          &nbsp;
        </Box>
      )}
      {selected && (
        <Icon
          aria-label={`${name}-checkbox`}
          as={SelectedIcon}
          borderRadius="4px"
          boxSize="20px"
          role="checkbox"
        />
      )}
      <Text
        color={EColor.Neutral65}
        variant={isSubcategory ? ETextVariant.SmallBold : ETextVariant.Small}
      >
        {name}
      </Text>
    </Flex>
  );
}

FilterItem.propTypes = {
  name: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  isSubcategory: PropTypes.bool,
};

export default FilterItem;
