import React from 'react';

import { createIcon } from '@chakra-ui/react';

export const EyeAppealEIcon = createIcon({
  viewBox: '0 0 19 24',
  path: (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 19 24"
      width="19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill="black" height="21" width="19" y="3" />
      <rect fill="#18BDE8" height="3" width="19" />
      <path
        d="M13.2095 8V9.74429H7.61521V12.4314H12.9738V14.1914H7.61521V17.2557H13.4452V19H5.55664V8H13.2095Z"
        fill="white"
      />
    </svg>
  ),
});
