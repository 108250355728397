import { useMemo } from 'react';

import { getFilterByValue } from '../../Filters/utils/utils';

export function useActiveFilters(filters) {
  const activeFilters = useMemo(() => {
    const entries = Object.entries(filters);
    const filterValues = entries.flatMap(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map((v) => ({
          key,
          value: v,
          name: getFilterByValue(v, key)?.name,
        }));
      }

      return {
        key,
        value,
        name: getFilterByValue(value, key)?.name,
      };
    });

    return filterValues.filter((filter) => !!filter.value);
  }, [filters]);

  return activeFilters;
}
