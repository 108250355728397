import React from 'react';

import { Box, VStack, Text } from '@chakra-ui/react';
import { EColor, ETextVariant } from '@fanatics-live/common-components';
import PropTypes from 'prop-types';
import FilterItem from '~/components/FilterItem';

function GradingCompanySection({ filters, setFilters, gradingCompany }) {
  return (
    <Box pb="24px">
      <Text color={EColor.Black} my="24px" variant={ETextVariant.SmallBold}>
        Authenticators
      </Text>
      <VStack alignItems="flex-start" spacing="16px" width="100%">
        <FilterItem
          name="All"
          selected={
            !filters.gradingService || filters.gradingService.length < 1
          }
          onClick={() =>
            setFilters({
              ...filters,
              gradingService: null,
              page: null,
            })
          }
        />
        {gradingCompany.map((c) => {
          const isSelected =
            (filters.gradingService &&
              filters.gradingService.indexOf(c.name) !== -1) ||
            false;
          return (
            <FilterItem
              key={`grader-${c.name}`}
              name={c.name}
              selected={isSelected}
              onClick={() => {
                let removed;
                if (isSelected) {
                  removed = [...filters.gradingService];
                  removed.splice(filters.gradingService.indexOf(c.name), 1);
                }
                setFilters({
                  ...filters,
                  gradingService: isSelected
                    ? [...removed]
                    : [...(filters.gradingService || []), c.name],
                  page: null,
                });
              }}
            />
          );
        })}
      </VStack>
    </Box>
  );
}

GradingCompanySection.propTypes = {
  setFilters: PropTypes.func,
  filters: PropTypes.object,
  gradingCompany: PropTypes.array,
};

export default GradingCompanySection;
