import { useAuth } from '@fanatics-live/common-components';

export const useGTMEvents = () => {
  const auth = useAuth();
  const userId = auth?.profile?.userId;

  const pushGTMEvent = (event) => {
    const GTMEvent = {
      event,
      pwccAccountId: userId,
    };

    if (typeof window !== 'undefined' && window.dataLayer && event && userId) {
      window.dataLayer.push(GTMEvent);
    }
  };

  return { pushGTMEvent };
};
