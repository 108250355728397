import React from 'react';

import { Text, Flex } from '@chakra-ui/react';
import {
  EColor,
  ETextVariant,
  maxWidths,
} from '@fanatics-live/common-components';

function ResearchHeader() {
  return (
    <Flex
      justifyContent="center"
      pb="24px"
      pt={{ base: '24px', md: '59px' }}
      width="100%"
    >
      <Flex
        color={EColor.Black}
        flexDirection="column"
        maxWidth={maxWidths}
        px="15px"
        width="100%"
      >
        <Text
          as="h1"
          fontSize={{ base: '40px', md: '56px' }}
          letterSpacing="-2px"
          pb="24px"
          variant={ETextVariant.XL4}
        >
          Sold Items
        </Text>
        <Text variant={ETextVariant.BaseParagraph}>
          Sold Items include sales across all markets on the Fanatics Collect
          platform. To provide accurate market information, if a payment is not
          received for a sale it is removed from the sales history.
        </Text>
      </Flex>
    </Flex>
  );
}

export default ResearchHeader;
