import React from 'react';

import {
  Box,
  Flex,
  NumberInput,
  NumberInputField,
  Text,
} from '@chakra-ui/react';
import { EColor, ETextVariant } from '@fanatics-live/common-components';
import PropTypes from 'prop-types';

function CardYearSection({ filters, setFilters, defaultYears }) {
  return (
    <Box borderBottomColor={EColor.Neutral10} borderBottomWidth="1px" pb="24px">
      <Text color={EColor.Black} my="24px" variant={ETextVariant.SmallBold}>
        Year
      </Text>
      <Flex alignItems="end" gap="20px" justify="center">
        <Box mr="5px">
          <Text color={EColor.Neutral65} mb="12px" variant={ETextVariant.XS}>
            From:
          </Text>
          <NumberInput
            max={filters.yearMax || defaultYears[1]}
            min={defaultYears[0]}
            value={filters.yearMin ?? ''}
            onChange={(value) =>
              setFilters({
                ...filters,
                yearMin: value,
              })
            }
          >
            <NumberInputField
              _focus={{
                boxShadow: 'none',
                border: '1px solid black',
              }}
              _placeholder={{
                fontSize: { base: '16px' },
                fontWeight: 400,
                lineHeight: { base: '26px' },
                color: EColor.Neutral35,
              }}
              borderColor={EColor.Neutral25}
              borderRadius="8px"
              placeholder="Enter Min"
              pr="12px"
            />
          </NumberInput>
        </Box>
        <Box>
          <Text color={EColor.Neutral65} mb="12px" variant={ETextVariant.XS}>
            To:
          </Text>
          <NumberInput
            max={defaultYears[1]}
            min={filters.yearMin || defaultYears[0]}
            value={filters.yearMax ?? ''}
            onChange={(value) =>
              setFilters({
                ...filters,
                yearMax: value,
              })
            }
          >
            <NumberInputField
              _focus={{
                boxShadow: 'none',
                border: '1px solid black',
              }}
              _placeholder={{
                fontSize: { base: '16px' },
                fontWeight: 400,
                lineHeight: { base: '26px' },
                color: EColor.Neutral35,
              }}
              borderColor={EColor.Neutral25}
              borderRadius="8px"
              placeholder="Enter Max"
              pr="12px"
            />
          </NumberInput>
        </Box>
      </Flex>
    </Box>
  );
}

CardYearSection.propTypes = {
  setFilters: PropTypes.func,
  filters: PropTypes.object,
  defaultYears: PropTypes.array,
};

export default CardYearSection;
