import React from 'react';

import { createIcon } from '@chakra-ui/react';

export const EyeAppealSIcon = createIcon({
  viewBox: '0 0 19 24',
  path: (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 19 24"
      width="19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill="black" height="21" width="19" y="3" />
      <rect fill="#FFAD68" height="3" width="19" />
      <path
        d="M7.14589 15.144C7.19571 15.8514 7.47469 16.3895 7.98285 16.7582C8.47107 17.1467 9.08384 17.341 9.82116 17.341C11.2161 17.341 11.9136 16.8628 11.9136 15.9062C11.9136 15.159 11.3406 14.6757 10.1948 14.4565L8.41627 14.1128C7.52949 13.9334 6.82207 13.5947 6.29399 13.0965C5.75594 12.5784 5.48692 11.9307 5.48692 11.1535C5.48692 9.88813 6.14951 8.97147 7.47469 8.40353C8.08248 8.13451 8.77995 8 9.56708 8C10.8424 8 11.8488 8.30888 12.5861 8.92663C13.3135 9.55435 13.707 10.3913 13.7668 11.4375H11.7342C11.7242 10.9094 11.525 10.486 11.1364 10.1671C10.7378 9.82835 10.1749 9.65897 9.44752 9.65897C8.85966 9.65897 8.37641 9.77355 7.99779 10.0027C7.62913 10.2518 7.4448 10.5806 7.4448 10.9891C7.4448 11.3578 7.58928 11.6418 7.87823 11.841C8.12732 12.0005 8.57071 12.1499 9.20839 12.2894L10.972 12.6332C12.9149 13.0118 13.8864 14.0082 13.8864 15.6223C13.8864 16.6286 13.5227 17.4357 12.7953 18.0435C12.058 18.6812 11.0367 19 9.73149 19C8.3266 19 7.23058 18.6513 6.44344 17.9538C5.63638 17.2663 5.19299 16.3297 5.11328 15.144H7.14589Z"
        fill="white"
      />
    </svg>
  ),
});
