import PropTypes from 'prop-types';

export default PropTypes.oneOf([
  'WEEKLY',
  'MONTHLY',
  'FIXED',
  'PREMIER',
  'FLASH',
  '',
]);
