import React from 'react';

import { Button, Flex, Text } from '@chakra-ui/react';
import { EButtonVariant, EColor } from '@fanatics-live/common-components';
import PropTypes from 'prop-types';
import { FilterIcon } from '~/components/icons/FilterIcon';

function FilterButton({ showFilters, setShowFilters }) {
  return (
    <Flex>
      <Button
        _active={{ borderColor: EColor.Neutral25 }}
        borderColor={EColor.Neutral25}
        gap="4px"
        p="12px"
        variant={EButtonVariant.BRAND_SECONDARY}
        onClick={() => {
          setShowFilters(!showFilters);
        }}
      >
        <FilterIcon height="24px" width="24px" />
        <Text
          color={EColor.Black}
          fontFamily="ABC Diatype"
          fontSize="12px"
          fontWeight={700}
          textTransform="capitalize"
        >
          Filters
        </Text>
      </Button>
    </Flex>
  );
}

FilterButton.propTypes = {
  showFilters: PropTypes.bool,
  setShowFilters: PropTypes.func,
};

export default FilterButton;
