import React, { useState, useEffect } from 'react';

import { Flex } from '@chakra-ui/react';
import { maxWidths } from '@fanatics-live/common-components';
import PropTypes from 'prop-types';

import FilterButton from './FilterButton';
import SearchBar from './SearchBar';
import { FiltersDrawer } from '../Filters';

function SearchFilterBar({
  showFilters,
  setShowFilters,
  filters,
  setFilters,
  isPending,
}) {
  const [searchText, setSearchText] = useState(filters.title || '');
  useEffect(() => {
    setSearchText(filters.title || '');
  }, [filters.title]);

  return (
    <Flex
      alignItems="center"
      direction="column"
      justifyContent={{ base: 'flex-end', md: 'flex-start' }}
      paddingBottom={{ base: '10px', md: '15px' }}
    >
      <Flex
        flexDirection={{ base: 'row-reverse', md: 'row' }}
        gap="16px"
        justifyContent={{ base: 'flex-end', md: 'flex-start' }}
        maxWidth={maxWidths}
        px="15px"
        width="100%"
      >
        <SearchBar
          filters={filters}
          isPending={isPending}
          searchText={searchText}
          setFilters={setFilters}
          setSearchText={setSearchText}
        />
        <FilterButton
          setShowFilters={setShowFilters}
          showFilters={showFilters}
        />
      </Flex>
      <FiltersDrawer
        filters={filters}
        setFilters={setFilters}
        setShowFilters={setShowFilters}
        showFilters={showFilters}
      />
    </Flex>
  );
}

SearchFilterBar.propTypes = {
  showFilters: PropTypes.bool,
  setShowFilters: PropTypes.func,
  filters: PropTypes.any,
  setFilters: PropTypes.func,
  isPending: PropTypes.bool,
};

export default SearchFilterBar;
