import PropTypes from 'prop-types';

import AuctionType from './AuctionType';

export default PropTypes.shape({
  id: PropTypes.string,
  refId: PropTypes.number,
  auctionType: AuctionType,
  gradingService: PropTypes.string,
  grade: PropTypes.number,
  eyeAppealGrade: PropTypes.string,
  year: PropTypes.number,
  category: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  purchasePrice: PropTypes.string,
  soldDate: PropTypes.string,
  mediumImage1: PropTypes.string,
  mediumImage2: PropTypes.string,
  largeImage1: PropTypes.string,
  largeImage2: PropTypes.string,
  youtubeKey: PropTypes.string,
});
