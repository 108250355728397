import { useCallback } from 'react';

import useSWRInfinite from 'swr/infinite';
import { formatNumber, makeURLParams } from '~/utils/utils';

const PAGE_SIZE = 20;
export const useSearchResults = (filters, initialSearchData, loggedIn) => {
  const {
    data,
    size,
    setSize,
    isValidating: isPending,
  } = useSWRInfinite(
    (pageIndex, previousPage) =>
      previousPage && previousPage.page.number >= previousPage.page.totalPages
        ? null
        : `/api/v1${loggedIn ? '' : '/pub'}/sales?${makeURLParams(
            filters
          )}&page=${pageIndex}&size=${PAGE_SIZE}`,
    {
      initialSize: 1,
      revalidateFirstPage: false,
      shouldRetryOnError: false,
    }
  );

  const getNextPage = useCallback(() => setSize(size + 1), [setSize, size]);

  if (!data) return { getNextPage, isPending, totalElements: '0' };

  const internalTotalRecords = data[data.length - 1]?.page?.totalElements;

  const totalElements = internalTotalRecords
    ? formatNumber(internalTotalRecords)
    : '0';

  const searchData = data?.flatMap((page) => page?._embedded?.SalesRecords);

  const hasMorePages = searchData ? searchData.length % PAGE_SIZE === 0 : false;

  return {
    searchData,
    totalElements,
    getNextPage,
    hasMorePages,
    isPending,
  };
};
