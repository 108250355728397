import React, { useEffect, useMemo, useState } from 'react';

import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Text,
  Flex,
  Button,
} from '@chakra-ui/react';
import {
  EButtonVariant,
  EColor,
  ETextVariant,
} from '@fanatics-live/common-components';
import PropTypes from 'prop-types';
import filterParams from '~/features/research/types/Filters';

import CardYearSection from './CardYearSection';
import CategorySection from './CategorySection';
import EyeAppealSection from './EyeAppealSection';
import GradingCompanySection from './GradingCompanySection';
import MarketplaceSection from './MarketplaceSection';
import SortBySection from './SortBySection';
import { filterOptions } from './utils/constants';
import { getTotalFilters } from './utils/utils';

function FiltersDrawer({
  filters,
  setFilters,
  name,
  image,
  price,
  loggedIn,
  showFilters,
  setShowFilters,
  ...rest
}) {
  const [protoFilters, setProtoFilters] = useState(filters);
  const defaultYears = useMemo(() => [1900, new Date().getFullYear()], []);

  useEffect(() => {
    setProtoFilters(filters);
  }, [filters]);

  const totalFilters = useMemo(() => {
    return getTotalFilters(protoFilters);
  }, [protoFilters]);

  const applyFilters = () => {
    setFilters(protoFilters);
    setShowFilters(false);
    window.scrollTo(0, 0);
  };

  const resetFilters = () => {
    setFilters({});
    setShowFilters(false);
    window.scrollTo(0, 0);
  };

  return (
    <Drawer
      blockScrollOnMount
      preserveScrollBarGap
      isOpen={showFilters}
      placement="left"
      onClose={() => setShowFilters(false)}
    >
      <DrawerOverlay />
      <DrawerContent maxW="378px">
        <DrawerHeader
          borderBottomColor={EColor.Neutral10}
          borderBottomWidth="1px"
          fontSize="20px"
          fontWeight={900}
          paddingX="24px"
          paddingY="16px"
          textAlign="left"
        >
          <DrawerCloseButton
            color={EColor.Black}
            height="24px"
            left={0}
            mb="18px"
            position="relative"
            width="24px"
          />

          <Flex alignItems="center" justifyContent="space-between">
            <Text
              color={EColor.Black}
              letterSpacing={0}
              variant={ETextVariant.XL}
            >
              Filters
            </Text>
            {totalFilters > 0 && (
              <Text
                _hover={{
                  color: EColor.DarkBlue,
                }}
                as="button"
                color={EColor.Blue50}
                textDecoration="underline"
                variant={ETextVariant.Small}
                onClick={resetFilters}
              >
                {`Reset all (${totalFilters})`}
              </Text>
            )}
          </Flex>
        </DrawerHeader>

        <DrawerBody
          px="24px"
          py={0}
          sx={{
            '&::-webkit-scrollbar': {
              width: '16px',
              borderRadius: '100px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: EColor.BrandLightGray,
              border: '6px solid rgba(0, 0, 0, 0)',
              borderRadius: '100px',
              backgroundClip: 'padding-box',
            },
          }}
        >
          <SortBySection
            filters={protoFilters}
            setFilters={setProtoFilters}
            sorts={filterOptions.sort}
          />
          <MarketplaceSection
            filters={protoFilters}
            marketplaces={filterOptions.auctionTypes}
            setFilters={setProtoFilters}
          />
          <CardYearSection
            defaultYears={defaultYears}
            filters={protoFilters}
            setFilters={setProtoFilters}
          />
          <CategorySection
            categories={filterOptions.category}
            filters={protoFilters}
            otherItems={filterOptions.otherItems}
            setFilters={setProtoFilters}
          />
          <EyeAppealSection
            eyeAppeal={filterOptions.eyeAppealGrade}
            filters={protoFilters}
            setFilters={setProtoFilters}
          />
          <GradingCompanySection
            filters={protoFilters}
            gradingCompany={filterOptions.gradingService}
            setFilters={setProtoFilters}
          />
        </DrawerBody>

        <DrawerFooter
          borderTopColor={EColor.Neutral10}
          borderTopWidth="1px"
          paddingX="24px"
        >
          <Button
            variant={EButtonVariant.BRAND_SECONDARY}
            width="100%"
            onClick={applyFilters}
          >
            Apply
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

FiltersDrawer.propTypes = {
  filters: filterParams,
  setFilters: PropTypes.func,
  showFilters: PropTypes.bool,
  setShowFilters: PropTypes.func,
  name: PropTypes.string,
  image: PropTypes.string,
  price: PropTypes.string,
  isSuper: PropTypes.bool,
  loggedIn: PropTypes.bool,
  soldIn: PropTypes.string,
};

export default FiltersDrawer;
